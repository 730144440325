/* You can add global styles to this file, and also import other style files */
/* Target the scrollbar itself */
::-webkit-scrollbar {
  width: 10px; /* Adjust width as desired */
  background-color: #f5f5f5; /* Background color */
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Thumb color */
  border-radius: 5px; /* Add rounded corners */
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #eee; /* Track color */
}

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Noto Sans';
  src: url(./assets/fonts/NotoSans-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(./assets/fonts/NotoSans-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(./assets/fonts/NotoSans-Italic.ttf);
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(./assets/fonts/NotoSans-BoldItalic.ttf);
  font-style: italic;
  font-weight: 700;
}

:root {
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  --font-family: var(--font-noto-sans);
  --font-noto-sans-bold: 'Noto Sans Bold';
  --font-noto-sans-italic: 'Noto Sans Italic';
  --font-noto-sans-bold-italic: 'Noto Sans Bold Italic';
  --font-noto-sans-regular: 'Noto Sans';
}

h5,
h3,
h1,
p,
table,
td,
tr,
form,
button,
a,
div {
  font-family: var(--font-noto-sans-regular);
}

.font-noto-sans {
  font-family: var(--font-noto-sans-regular);
}

.font-noto-sans-bold {
  font-family: var(--font-noto-sans-bold);
  font-weight: 700;
}

.font-noto-sans-italic {
  font-family: var(--font-noto-sans-italic);
  font-style: italic;
}

.font-noto-sans-bold-italic {
  font-family: var(--font-noto-sans-bold-italic);
  font-weight: 700;
  font-style: italic;
}

.shepherd-element {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3) !important;
}

.shepherd-button {
  background-color: #d91e18 !important;
  color: #ffffff !important;
  border-radius: 2rem !important;
}

.shepherd-button-secondary {
  background-color: #ffffff !important;
  color: #d91e18 !important;
  border: 1px solid #d91e18 !important;
}
